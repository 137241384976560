import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor() { }
  
  private _ids : number = 0;
  /* Observable source */
  private displaySource = new BehaviorSubject(false);

  /* Observable stream */
  private display$ = this.displaySource.asObservable();

  async display() {
    this._ids = this._ids + 1;
    this.displaySource.next(true);
  }

  async dismiss() {
    this._ids = this._ids - 1;
    this.displaySource.next(true);
  }
  
  onDisplayChange(): Observable<boolean> {
    return this.display$;
  }

  get displayStatus() : boolean {
    return this._ids > 0;
  }
}