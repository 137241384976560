import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService, Message } from 'primeng/api';
import { finalize, map, mergeMap, Observable, of, retryWhen, shareReplay, Subject, tap } from 'rxjs';
import { AUTHORIZATION } from 'src/app/app.constants';
import { SpinnerService } from 'src/app/layout/spinner/spinner.service';
import { AuthenticationService } from '../service/authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  // requestqueue = new Subject<any>();

  $tokenSubject = new Subject<any>();

  constructor(private spinnerService: SpinnerService, 
    private authentication: AuthenticationService, 
    private messageService: MessageService,
    private router: Router) {
      
  }

  // Intercept all the HTTP requests.
  // Check if user has valid token to init API call
  // If response is 401, log user out
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.spinnerService.display();
    const HTTP_TYPES_TO_ADD_TOKEN = ['GET', 'POST'];

    if (request.url.indexOf('/auth/login') > 0) {
      this.spinnerService.dismiss();
      return next.handle(request);
    }
    
    return of(request).pipe(
      mergeMap(() => {
          if (HTTP_TYPES_TO_ADD_TOKEN.indexOf(request.method.toUpperCase()) !== -1) {
            // prevent IE from using cached response for http GET
            if (request.method.toUpperCase() === 'GET') {
              request = request.clone({
                setHeaders: {
                  'Cache-Control': 'no-cache',
                  'Pragma': 'no-cache',
                  'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                  'If-Modified-Since': '0'
                }
              });
            }
      
            const token = localStorage.getItem(AUTHORIZATION);
            console.log('httpCSRFInterceptor attaching token to request , token: ' + token);
            if (!!token) {
              request = request.clone({
                setHeaders: {
                  AUTHORIZATION: token
                }
              });
            }
          }

        return next.handle(request).pipe(
          // retryWhen(genericRetryStrategy({
          //   shouldRetry
          // })),
          // Limit the request to be in sequence. (to avoid concurrent requests which shares the same JSESSIONID, causing 401)
          shareReplay({ bufferSize: 1, refCount: true }),
          tap({
            next: (event) => {
              console.log(event);
            },
            error: (error: HttpErrorResponse) => {
              if (error.status == 401) {
                console.log('Unauthorized request');
                this.messageService.add({severity:'error', summary:'', detail:'Please log in'});
                this.authentication.logout_redirect(this.router.url);
              } else {
                console.error(error);
                this.messageService.add({severity:'error', summary:'', detail: error.error?.message});
              }
            },
          }),
          finalize(
            () => {
              // console.log("dismiss spinner");
              this.spinnerService.dismiss();
            }
          )
        );
        }
      )
    );
  }
}
