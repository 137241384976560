<div class="layout-topbar">

    <!-- <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button> -->

    <a class="layout-topbar-logo" style="margin-left: 10px;">
        <img src="assets/layout/images/logo.png" alt="logo">
        <span style="font-size: 1.3rem;">{{display_name}}</span>
    </a>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </button>
        <button class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button> -->
        <button class="p-link layout-topbar-button" (click)="logout()">
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
        </button>
    </div>
</div>