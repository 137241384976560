import { API_GATEWAY } from 'src/environments/environment';

// Customer Service URL
export const CUSTOMER_LIST = `${API_GATEWAY}/customers/all-customer`;
export const PM_LIST = `${API_GATEWAY}/customers/all-procurement-manager`;
export const CUSTOMER_GET_BY_PM_ID = `${API_GATEWAY}/customers/all-assigned-customer-pm/`;
export const CUSTOMER_GET_BY_PM_USER_ID = `${API_GATEWAY}/customers/all-assigned-customer-pm-by-user/`;
export const CUSTOMER_SAVEALL = `${API_GATEWAY}/customers/saveall`;
export const CUSTOMER_GET_BY_ID = `${API_GATEWAY}/customers/`;
export const CUSTOMER_GET_BY_CUSTOMER_USER_ID = `${API_GATEWAY}/customers/customer-user-id/`;
export const CUSTOMER_GET_BY_SALES_USER_ID = `${API_GATEWAY}/customers/sales-user-id/`;
export const ITEM_LIST = `${API_GATEWAY}/item/all`;
export const ITEMSET_LIST = `${API_GATEWAY}/item/all-itemset`;
export const ITEMSET_BY_CUSTOMER_LIST = `${API_GATEWAY}/item/itemset-by-customer/`;
export const ITEM_SAVE = `${API_GATEWAY}/item/save`;
export const ITEMSET_SAVEALL = `${API_GATEWAY}/item/saveall-itemset`;
export const ITEM_GET_BY_ID = `${API_GATEWAY}/item/`;

export const ORDER_LIST = `${API_GATEWAY}/order/all`;
export const ORDER_GET_BY_ID = `${API_GATEWAY}/order/id/`;
export const ORDER_SAVE = `${API_GATEWAY}/order/save`;
export const ORDER_DOWNLOAD = `${API_GATEWAY}/order/download`;
export const USER_SAVE = `${API_GATEWAY}/user/save`;

export const COUNTRY_LIST = `${API_GATEWAY}/data/countries`;
export const LOCATION_LIST = `${API_GATEWAY}/data/locations`;
export const DEPARTMENT_LIST = `${API_GATEWAY}/data/departments`;
export const PRICE_LEVEL_LIST = `${API_GATEWAY}/data/priceList`;
export const TAX_CODE_LIST = `${API_GATEWAY}/data/taxCode`;
export const UOM_LIST = `${API_GATEWAY}/data/uom`;
export const FORM_CONFIGURATION = `${API_GATEWAY}/data/configuration/`;

export const DATA_UOM_BY_ID = `${API_GATEWAY}/unit/uom/`;
export const DATA_UNIT_BY_UOM_ID = `${API_GATEWAY}/unit/units-by-uom/`;
export const DATA_UOM_SAVE = `${API_GATEWAY}/unit/save`;

export const CALENDAR_BLOCK_LIST = `${API_GATEWAY}/data/calendar/all`;
export const CALENDAR_BLOCK_SAVE = `${API_GATEWAY}/data/calendar/saveall`;


export const LOGIN_URL = `${API_GATEWAY}/auth/login`;
export const RESET_PASSWORD_AUTH = `${API_GATEWAY}/auth/reset-password-authorised`;
export const VALIDATE_RESET_TOKEN = `${API_GATEWAY}/auth/validate-token`;
export const RESET_PASSWORD_TOKEN = `${API_GATEWAY}/auth/reset-password-token`;
export const FORGET_PASSWORD = `${API_GATEWAY}/auth/forget-password`;

export const USER_LIST = `${API_GATEWAY}/user/all`;
export const USER_SALESPERSON_LIST = `${API_GATEWAY}/user/salesperson/all`;
export const USER_ADMIN_LIST = `${API_GATEWAY}/user/ausers`;
export const USER_BY_ID = `${API_GATEWAY}/user/`;
export const USER_SALESPERSON_BY_ID = `${API_GATEWAY}/user/salesperson/`;
export const USER_SALESPERSON_BY_USERID = `${API_GATEWAY}/user/salesperson/userid/`;
export const USER_GET_BY_USERNAME = `${API_GATEWAY}/user/get?username=`;
export const USER_GET_CURRENT_AUTHENTICATION = `${API_GATEWAY}/user/get-current-authentication`;
export const USER_GET_DISPLAY_NAME_BY_ID = `${API_GATEWAY}/user/display-name/`;

export const AUTHORIZATION = 'Authorization';
export const USER = 'user';

export const FORM_MODE_NEW : number = 0
export const FORM_MODE_VIEW : number = 1
export const FORM_MODE_EDIT : number = 2

export const ROLE_SALESPERSON = 'ROLE_SALES';
export const ROLE_CUSTOMER = 'ROLE_CUSTOMER';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_PM = 'ROLE_PM';