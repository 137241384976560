import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map, catchError, throwError, lastValueFrom, filter } from 'rxjs';
import { AUTHORIZATION, RESET_PASSWORD_TOKEN, LOGIN_URL, RESET_PASSWORD_AUTH, ROLE_ADMIN, ROLE_CUSTOMER, ROLE_SALESPERSON, USER, USER_GET_CURRENT_AUTHENTICATION, USER_GET_DISPLAY_NAME_BY_ID, VALIDATE_RESET_TOKEN, FORGET_PASSWORD, ROLE_PM, USER_BY_ID } from 'src/app/app.constants';
import jwtDecode from 'jwt-decode';
import { decode } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _userDetail!: UserDetail;
  private _decoded: any;

  private loginSource = new BehaviorSubject(null);

  private login$ = this.loginSource.asObservable();

  private display_name = new BehaviorSubject(null);

  display_name$ = this.display_name.asObservable();

  constructor(private http: HttpClient) {
      // this.resetStorage();
  }

  // Use LocalStorage to store current JWT token and user info
  private resetStorage() {
      localStorage.removeItem(AUTHORIZATION);
      localStorage.removeItem(USER);
      localStorage.removeItem('Master');
  }

  // Reset LocalStorage JWT token and user info
  public resetAuthStorage() {
    localStorage.removeItem(AUTHORIZATION);
    localStorage.removeItem(USER);
    localStorage.removeItem('Master');
  }

  // Realtime check, if current user is authenticated
  // User is considered not authenticated if logged out, or token expired, or backend server restarted
  public isAuthenticated(): boolean {
    let authorisation = localStorage.getItem(AUTHORIZATION);
    console.log("authorisation: ");
    console.log(authorisation);
    if (authorisation != null) {
      let jwtToken = authorisation.replace('Bearer ', '');
      const decoded : any = jwtDecode(jwtToken);
      console.log("decoded: ")
      console.log(decoded)
      let exp = decoded['exp'];
      if (Date.now()/1000 >= exp) {
        console.warn('token expired');
        return false;
      }
    }

    return authorisation !== null && authorisation.length > 0;
  }

  // Check authentication status without calling API
  public getCachedAuthentication() : any {
    let authorisation = localStorage.getItem(AUTHORIZATION);
    // console.log("authorisation: ");
    // console.log(authorisation);
    if (authorisation != null) {
      let jwtToken = authorisation.replace('Bearer ', '');
      const decoded : any = jwtDecode(jwtToken);
      // console.log("decoded: ")
      // console.log(decoded)
      return decoded;
    }
    return null;
  }

  // Get current logged in user authentication API call
  public getAuthentication() {
    return lastValueFrom(this.http.get<any>(USER_GET_CURRENT_AUTHENTICATION));
  }

  // Get current user display name
  public getDisplayName() {
    return this.http.get<any>(USER_GET_DISPLAY_NAME_BY_ID + this.userid).subscribe(
      data => {
        this.display_name.next(data['name'].split('_')[0]);
      }
    );
  }

  // Login method when login button clicked. Call login API and authenticate
  login(username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(LOGIN_URL, {
        "username": username,
        "password": password
      }, {observe: 'response'}).subscribe({
        next: data => {
          // debugger
          let token = data.headers.get("Authorization") || "";
          console.log("login response: " + token)

          let jwtToken = token.replace('Bearer ', '');
          const decoded = jwtDecode(jwtToken);
          console.log("decoded: " + decoded)

          localStorage.setItem(AUTHORIZATION, token);
          localStorage.setItem(USER, username);

          let userId = this.jwtClaims['id'];
          lastValueFrom(this.http.get<any>(USER_BY_ID + userId)).then(
            data => {
              localStorage.setItem('Master', data.master);
              resolve(data)
            }
          )
        },
        error: error => {
          console.error('There was an error!', error);
          reject(error)
        }
      })
    });
  }

  // Logout current user
  logout() {
    this.resetStorage();
    window.location.reload();
  }

  logout_redirect(url: string) {
    this.resetStorage();
    localStorage.setItem('URL', url);
    window.location.reload();
  }

  get redirect_to() : string {
    let url = localStorage.getItem('URL');
    localStorage.removeItem('URL');
    return url === '' || url == undefined || url == null ? '': url;
  }

  // Get the decoded current JWT
  get jwtClaims() : any {
    let token = localStorage.getItem(AUTHORIZATION);

    if (!token) return null;

    if (this.decoded) {
      return this.decoded;
    } else {
      let jwtToken = token.replace('Bearer ', '');
      const decoded = jwtDecode(jwtToken);
      // console.log("decoded: ", decoded)
  
      return decoded;
    }
  }

  // Get current user role
  get userRole() {
    let claims = this.jwtClaims
    console.log("userRole: ", claims)
    if (!claims) return null;

    let roles = claims['role']
    if (!roles || roles.length == 0) return null;

    return roles[0]['authority'];
  }

  // Get current user id
  get userid() {
    let claims = this.jwtClaims
    console.log("userid: ", claims)
    if (!claims) return null;
    let id = claims['id']
    return id;
  }

  // Get current user name
  get username() {
    let claims = this.jwtClaims
    console.log("username: ", claims)
    if (!claims) return null;

    let username = claims['username']

    return username;
  }

  // If current user has the input role
  ifRoleExists(matchingrole: string) : boolean {
    let claims = this.jwtClaims
    // console.log("ifRoleExists: ", claims)
    if (!claims) return false;

    let roles = claims['role']
    if (!roles || roles.length == 0) return false;

    var hasMatch : boolean = false;

    for (let role of roles) {
      if (role['authority'] == matchingrole) {
        hasMatch = true;
        break;
      }
    }

    return hasMatch;
  }

  // If current user is Admin
  get isUserAdmin() {
    return this.ifRoleExists(ROLE_ADMIN);
  }

  // If current user is Customer
  get isUserCustomer() {
    return this.ifRoleExists(ROLE_CUSTOMER);
  }

  // If current user is sales person
  get isUserSales() {
    return this.ifRoleExists(ROLE_SALESPERSON);
  }

  get isUserMasterAdmin() {
    return localStorage.getItem('Master')?.toLowerCase() === 'true';
  }

  // If current user is sales person
  get isUserPM() {
    return this.ifRoleExists(ROLE_PM);
  }

  get userDetail() {
      return this._userDetail;
  }

  set userDetail(value: UserDetail) {
      this._userDetail = value;
      this.broadcastLogin();
  }

  get decoded() {
    return this._decoded;
  }

  private broadcastLogin() {
  }

  get loginObs(): Observable<any> {
    return this.login$;
  }

  // Call Reset Password API
  resetPassword(userid: any, password: any) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(RESET_PASSWORD_AUTH, {
        userid: userid,
        password: password
      }).subscribe({
        next: data => {
            resolve(data)
        },
        error: error => {
            console.error('There was an error!', error);
            reject(error)
        }
      })
    })
  }

  // Call Forget Password API
  forgotPassword(email: any) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(FORGET_PASSWORD, {
        email: email
      }).subscribe({
        next: data => {
            resolve(data)
        },
        error: error => {
            console.error('There was an error!', error);
            reject(error)
        }
      })
    })
  }

  // Verify reset password token 
  verifyResetToken(email: any, token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(VALIDATE_RESET_TOKEN, {
        email: email,
        token: token
      }).subscribe({
        next: data => {
            resolve(data)
        },
        error: error => {
            console.error('There was an error!', error);
            reject(error)
        }
      })
    })
  }

  // Reset password in forget password
  resetPasswordWithToken(email: any, password: any, token: any) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(RESET_PASSWORD_TOKEN, {
        email: email,
        token: token,
        password: password
      }).subscribe({
        next: data => {
            resolve(data)
        },
        error: error => {
            console.error('There was an error!', error);
            reject(error)
        }
      })
    })
  }

}

export class UserDetail {
  id!: number;
  username!: string;
  email!: string;
  authorities!: string[];
}