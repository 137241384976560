import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthenticationService } from '../demo/service/authentication.service';
import { LayoutService } from "./service/app.layout.service";
import { Subject } from 'rxjs';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    display_name: string = '';

    constructor(public layoutService: LayoutService, 
        private authenticationService: AuthenticationService) {

            this.authenticationService.display_name$.subscribe(
                name_response => {
                    if (name_response) {
                        this.display_name = name_response;
                    }
                }
            )

        // this.authenticationService.getDisplayName().subscribe(
        //     name_response => {
        //         console.log(name_response);
        //         this.display_name = name_response['name'].split('_')[0];
        //     }
        // )
        // this.modulename = this.authenticationService.userRole;
        // this.modulename = this.capitalizeFirstLetter(this.modulename.replace('ROLE_', '').toLowerCase());
    }

    logout() {
        this.authenticationService.logout();
    }

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
      
}
