import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements OnInit {

  display : boolean = false;
  constructor(private spinnerService: SpinnerService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.spinnerService.onDisplayChange().subscribe(
      () => {
        this.display = this.spinnerService.displayStatus;
        this.cdr.detectChanges();
        console.log("cp @ SpinnerComponent subscription display: ", this.display);
     })
  }

}