import { HttpClient, HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError, throwError, lastValueFrom } from 'rxjs';
import { CUSTOMER_GET_BY_CUSTOMER_USER_ID, CUSTOMER_GET_BY_ID, 
    CUSTOMER_GET_BY_PM_ID, CUSTOMER_GET_BY_PM_USER_ID, CUSTOMER_GET_BY_SALES_USER_ID, CUSTOMER_LIST, CUSTOMER_SAVEALL, PM_LIST } from 'src/app/app.constants';
import { Customer } from '../api/customer';

@Injectable()
export class CustomerService {

    constructor(private http: HttpClient) { }

    getCustomers() {
        return this.http.get<any>(CUSTOMER_LIST);
    }

    getProcurementManager() {
        return this.http.get<any>(PM_LIST);
    }

    getCustomer(id: any) {
        return this.http.get<any>(CUSTOMER_GET_BY_ID + id);
    }

    getCustomerByPMId(id: any) {
        return this.http.get<any>(CUSTOMER_GET_BY_PM_ID + id);
    }

    getCustomerByPMUserId(id: any) {
        return this.http.get<any>(CUSTOMER_GET_BY_PM_USER_ID + id);
    }

    getCustomerByCustomerUserId(id: string) {
        return this.http.get<any>(CUSTOMER_GET_BY_CUSTOMER_USER_ID + id);
    }

    getCustomersBySalesUserId(salesId: string) {
        return this.http.get<any>(CUSTOMER_GET_BY_SALES_USER_ID + salesId);
    }

    saveCustomers(payload: any[]) {
        return new Promise((resolve, reject) => {
          this.http.post<any>(CUSTOMER_SAVEALL, payload).subscribe({
            next: data => {
                resolve(data)
            },
            error: error => {
                console.error('There was an error!', error);
                reject(error)
            }
          })
        })
    }
}
