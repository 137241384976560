<div id="http-loader" [hidden]="!display">
    <div class="reveal-overlay">
      <div class="row reveal">
        <div class="col-12 text-center">
          <div class="sk-folding-cube">
            <div class="sk-cube1 sk-cube"></div>
            <div class="sk-cube2 sk-cube"></div>
            <div class="sk-cube4 sk-cube"></div>
            <div class="sk-cube3 sk-cube"></div>
          </div>
          <p style="margin-top:1em;text-align:center;">Please wait.</p>
        </div>
      </div>
    </div>
  </div>