import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  constructor() { }

  breadcrumbItems: MenuItem[] = [
    {
      label: 'Home',
      routerLink: ['/']
    }
  ];

  private menuSource = new Subject();

  menuSource$ = this.menuSource.asObservable();

  onMenuStateChange() {
      this.menuSource.next(true);
  }

  updateBreadcrumb(breadcrumbItems: MenuItem[]) {
    this.breadcrumbItems = breadcrumbItems;
    this.onMenuStateChange()
  }

  reset() {
    this.breadcrumbItems = [
      {
        label: 'Home',
        routerLink: ['/']
      }
    ];
    this.onMenuStateChange()
  }

}
