<div class="layout-wrapper" [ngClass]="containerClass">
    <app-spinner></app-spinner>
    <app-topbar></app-topbar>
    <!-- <div class="layout-sidebar">
        <app-sidebar></app-sidebar>
    </div> -->
    <div style="display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 70px 0rem 2rem 0rem;">
        <div class="layout-main">
            <p-breadcrumb [model]="breadcrumbItems" ></p-breadcrumb>
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
    <!-- <app-config></app-config> -->
    <div class="layout-mask"></div>
</div>
